/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> This is REDUX Reducers <<-----

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

const initialState = {
    loginStatus:false,
    loginData:[],
    cartCount:0,
    cartItems: [],
    selected:[],
    cartUser:{},
    products:[],
    backdrop:{status:false},
    body:"Stage1",
    banner:{category:"OIL_PRODUCTS"},
    customerDetails:[],
    totalAmount:0.0,
    dialogbox:{status:false, title:'', body:''},
    pindeliverycharge:0.0,
    grandtotal:0.0,
    userData : {username:"", code:"",forms:[],details:[],selectedForm:"",stage:1}
  };
  
  const counterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_EDIT':
            return {
                ...state,
                userData:{
                    ...state.userData,
                    username:action.username,
                    code:action.code,
                    forms:action.forms,
                    stage:action.stage,
                    details:action.details,
                    selectedForm:action.selectedForm,
                    assessment:action.assessment
                }
            }
        case 'USER_RESET':
            return {
                ...state,
                userData:{
                    ...state.userData,
                    username:"", code:"",forms:[],stage:1,details:[],selectedForm:"",assessment:[]
                }
            }
        case 'SELECTED_ADD':
            return{ ...state, selected:[...state.selected, action.payload] }
        case 'SELECTED_DELALL':
            return{ ...state, selected:[] }
        case 'SELECTED_DEL':
            return{ 
                ...state, 
                selected:state.selected.filter(item => !(item === action.payload))
            }
        case 'ADD_PRODUCTS':
            return{...state, products:[...state.products, action.payload]}
        case 'EDIT_PRODUCTS':
            return {
                ...state,
                products: state.products.map(item => {
                    if((item.pid === action.payload.pid)&&(item.did === action.payload.did)){
                        return {...item, selected: action.action}
                    }
                    return item;
                })
            }
        case 'UPDATE_CARTCOUNT':
            return { ...state, cartCount: action.count};
        case 'CHANGE_CATEGORY':
            return { ...state, banner:{...state.banner,category:action.payload}};
        case 'CART_ADD':
            return {
                    ...state, 
                    cartCount: state.cartCount + 1,
                    //cartItems:[...state.cartItems,action.payload]
                    cartItems:[...state.cartItems, {...action.payload,totalMrp: action.payload.totalItems * action.payload.mrp} ]
                }
        case 'CART_ITEMSUPDATE':
            return {
                ...state,
                cartItems: state.cartItems.map(item => {
                    if((item.pid === action.payload.pid)&&(item.did === action.payload.did)){
                        return {...item, totalItems: action.totalItems, totalMrp: action.totalMrp}
                    }
                    return item;
                })
            }
        case 'CART_DELETE':{
            return {
                ...state, 
                cartCount: action.count,
                cartItems:state.cartItems.filter(item => !(item.pid === action.payload.pid && item.did === action.payload.did)),
                products: state.products.map(item => {
                    if((item.pid === action.payload.pid && item.did === action.payload.did)){
                        return {...item, selected:false} 
                    }
                    return item
                })
            }
        }
        case 'CART_DELETE_ALL':
            return {...state, cartCount: 0,cartItems:[]}
        case 'CART_USER':
            return {...state ,cartUser:action.payload}
        case 'CUSTOMER_DETAILS':
            return {
              ...state,
              customerDetails: {
                ...action.payload,
                type: action.usertype
              }
            };
        case 'CUSTOMER_DETAILS_AREA':
            return {...state ,customerDetails:{...state.customerDetails,area:action.payload}}
        case 'BACKDROP_OFF':
            return {...state, backdrop:{...state.backdrop,status:false}}
        case 'BACKDROP_ON':
            return {...state, backdrop:{...state.backdrop,status:true}}
        case 'CHANGE_BODY':
            return {...state, body:action.payload}
        case 'TOTAL_AMOUNT':
            return {...state,totalAmount:action.payload}
        case 'LOGIN':
            return {...state,loginStatus:true,loginData:action.payload}
        case 'LOGOFF':
            return {...state,loginStatus:false,loginData:[]}
        case 'DIALOG_ON':
            return {
                ...state,
                dialogbox: {
                ...state.dialogbox,
                status: true,
                title: action.title,
                body: action.body
                }
            };              
        case 'DIALOG_OFF':
            return {...state, dialogbox:{...state.dialogbox, status:false, title:'', body:''}}
        case 'PIN_DELIVERY_CHARGE':
            return {...state,pindeliverycharge:action.payload}
        case 'GRAND_TOTAL':
            return {...state,grandtotal:action.payload}
        default:
            return state;
    }
  };
  
  export default counterReducer;