
import { useSelector } from "react-redux"
import { MdVerified } from "react-icons/md"

const Stage5 = () =>{
    const state = useSelector(state=>state)

    return <div style={{width:'100%', justifyContent:'center', textAlign:'center'}}>
        <div className="stdbox" style={{width:'500px',margin:'50px auto 20px', textAlign:'left',padding:'30px 50px 30px'}}>
            <div style={{fontSize:'80px',width:'100%',textAlign:'center'}}><MdVerified/></div>
            <div style={{width:'100%',textAlign:'center',fontSize:'30px'}}>Hi {state.userData.details.fname} {state.userData.details.lname},</div>
            <div style={{width:'100%',textAlign:'center', lineHeight:'30px', padding:'20px 0px 20px'}}>Thank you for taking the assessment. You will receive an email at <b>{state.userData.username}</b>. Please whitelist the domain greyinsights.org in your email client or check your spam folder. </div>
            {/*
            <div style={{width:'100%',textAlign:'justify', padding:'20px 0px 20px'}}>Based on your assessment for "{state.userData.assessment.form}", your score is {state.userData.assessment.average}. This score is related to category <b>{state.userData.assessment.category}</b>. {state.userData.assessment.comments}</div>
            */}
            <div style={{width:'100%',textAlign:'right'}}>{state.userData.details.Date}</div>
        </div>
    </div>
}

export default Stage5