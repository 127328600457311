/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> app.js  >  root.js  >  body.js  >  bizBody.js

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// Defaults
import {  useSelector } from "react-redux"
import { useEffect } from "react"

// Components
import Stage1 from "../../businessComponents/assessment/stage1"
import Stage2 from "../../businessComponents/assessment/stage2"
import Stage3 from "../../businessComponents/assessment/stage3"
import Stage4 from "../../businessComponents/assessment/stage4"
import Stage5 from "../../businessComponents/assessment/stage5"

const BizBody = () =>{

    // Primary Definitions
    const state = useSelector((state)=> state)



    useEffect(()=>{

        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Add smooth scrolling behavior if desired
          });
    },[state.body])

    // Business Methods
    const defineBody = () =>{


        if(state.body === "Stage1")
            return <Stage1/>
        if(state.body === "Stage2")
            return <Stage2/>
        if(state.body === "Stage3")
            return <Stage3/>
        if(state.body === "Stage4")
            return <Stage4/>
        if(state.body === "Stage5")
            return <Stage5/>
        else
            return <Stage1/>
    }

    // JSX
    return <div style={{justifySelf:'center',textAlign:'center',width:'100%'}}>
        {defineBody()}
    </div>
}

export default BizBody