/**
 * This code is Intellectual Property of Laxab Digital Solutions Pvt Ltd, which is a registered company in Pune, India.
 * No part of this code should be copied, edited, transmitted in any form without the written permission of the company.
 */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import {ValidateSQLInjection} from "../mui/validations"
import fetchData from "../api/api";

const Stage2 = () =>{
    const state = useSelector(state=>state)
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors }, trigger, setValue } = useForm();
    const currentDate = new Date();
    const time = currentDate.toDateString()+" "+currentDate.toLocaleTimeString();
    const [input,setinput] = useState({fname:""})

    const occupationDetails = [
        {name:'occupation', desc:'Enter your occupation', type:'text'},
        {name:'designation', desc:'What is your designation', type:'text'},
        {name:'years', desc:'How many years you have been working', type:'text'},
        {name:'jobsat', desc:'Are you satisfied with your current occupation', type:'select', options:["Satisfactory","Disagree"]},
        {name:'desigsat', desc:'Are you satisfied with your current designation', type:'select', options:["Satisfactory","Disagree"]},
        {name:'paysat', desc:'Are you satisfied with your current pay', type:'select', options:["Satisfactory","Disagree"]},
        {name:'promotion', desc:'Are you satisfied in terms of promotion', type:'select', options:["Satisfactory","Disagree"]},
        {name:'pensionsat', desc:'Are you satisfied with the pension scheme', type:'select', options:["Satisfactory","Disagree"]},
        {name:'welfaresat', desc:'Are you satisfied in terms of welfare your organisation is providing', type:'select', options:["Satisfactory","Disagree"]}
    ]
    const personalDetails = [
        {name:'fname', desc:'Enter First Name', type:'text'},
        {name:'lname', desc:'Enter Last Name', type:'text'},
        {name:'dob', desc:'Enter your date of birth', type:'date'},
        {name:'address', desc:'Enter Address', type:'text'},
        {name:'religion', desc:'Enter your religion', type:'select', options:["Christianity", "Islam", "Hinduism", "Buddhism" , "Agnostic", "Aethist", "Other"]},
        {name:'tribe', desc:'Enter Tribe Name', type:'text'},
        {name:'married', desc:'Enter your marital status', type:'select', options:["Single", "Married", "Divorsed", "Widow"]},
        {name:'gender', desc:'Enter your gender', type:'select', options:["Male","Female","Other"]}
    ]

    useEffect(()=>{
        dispatch({type:'BACKDROP_OFF'})


        const apiChecks = async() =>{
            dispatch({type:'BACKDROP_ON'})
            const url = 'api/ce/publicT3/select'
            const body = {
                "sid": 1,
                "request": "Public_Select",
                "bu":"GreyInsights",
                "type":"userinfo",
                "select":["*"],
                "condition":[{"userid":state.userData.username}],
                "conditionType":"AND",
                "order":{
                    "orderBy":"id",
                    "order":"DESC"
                },
                "pageNumber":1,
                "pageSize":5
            }
            const response = await fetchData(url,body)
            try{
                if(response.data.response.dbData.length>0){
                    setinput(response.data.response.dbData[0])
                    setValue('fname', response.data.response.dbData[0].fname);
                    setValue('lname', response.data.response.dbData[0].lname);
                    setValue('dob', response.data.response.dbData[0].dob);
                    setValue('address', state.userData.username);
                    setValue('religion', response.data.response.dbData[0].religion);
                    setValue('tribe', response.data.response.dbData[0].tribe);
                    setValue('married', response.data.response.dbData[0].married);
                    setValue('gender', response.data.response.dbData[0].gender);
                    setValue('occupation', response.data.response.dbData[0].occupation);
                    setValue('years', response.data.response.dbData[0].years);
                    setValue('designation', response.data.response.dbData[0].designation);
                    setValue('userid', state.userData.username);
                    await trigger("occupation");
                    await trigger("years");
                    await trigger("designation");
                    await trigger("fname");
                    await trigger("lname");
                    await trigger("dob");
                    await trigger("address");
                    await trigger("religion");
                    await trigger("tribe");
                    await trigger("married");
                    await trigger("gender");
                    await trigger("userid");
                    dispatch({type:'BACKDROP_OFF'});
                }
                else {dispatch({type:'BACKDROP_OFF'});}

            }
            catch (error) {
                dispatch({type:'BACKDROP_OFF'});
            }
            
        }
        apiChecks()


    },[dispatch, setValue, state.userData.username, trigger])
    const handleChange = async (e, type) => {
        if(type!=='address'){
            setinput(prev => ({ ...prev, [type]: e.target.value }));
            await trigger(e.target.name);
        }
      };
    const onSubmit = () => {
        var details = input
        details = {...input, Date:time, userid:state.userData.username, email:state.userData.username}
        delete details.id

        dispatch({
            type:"USER_EDIT",
            forms:state.userData.forms,
            username:state.userData.username,
            code:state.userData.code,
            details:details,
            stage:3
        })
        dispatch({type:"CHANGE_BODY",payload:"Stage3"})
    }
    const getForm = (personalDetails) =>{

        return personalDetails.map((item,index)=>(
            <div key={index} style={{padding:'0 20px 20px 0'}}>
                <div style={{padding:'0px 0 4px 5px'}}>{item.desc}</div>

                {
                    item.type==="text" && 
                    <div>
                        {
                            item.name!=="address"?
                                <input type='text' name={item.name} {...register(item.name,{required:"Mandatory",validate: ValidateSQLInjection})}
                                    onKeyUp={(e)=>handleChange(e,item.name)} placeholder={"Enter "+item.name} defaultValue={input[item.name]} 
                                style={{width:'100%'}} />
                            :
                                <input type='text' name={item.name} {...register(item.name,{required:"Mandatory",validate: ValidateSQLInjection})}
                                onKeyUp={(e)=>handleChange(e,item.name)} placeholder={"Enter "+item.name} defaultValue={state.userData.username} value={state.userData.username} 
                                style={{width:'100%'}} />
                        }
                        {errors[item.name] && <div style={{padding:'4px 0 0 5px',color:'#B5275E',fontSize:'small'}}>{errors[item.name].message}</div>}
                    </div>
                }

                {
                    item.type==="date" && 
                    <div>
                        <input type='date' name={item.name} {...register(item.name,{required:"Mandatory",validate: ValidateSQLInjection})}
                            onChange={(e)=>handleChange(e,item.name)} placeholder={"Enter "+item.name} defaultValue={input[item.name]} 
                        style={{width:'100%'}} />
                        {errors[item.name] && <div style={{padding:'4px 0 0 5px',color:'#B5275E',fontSize:'small'}}>{errors[item.name].message}</div>}
                    </div>
                }

                {
                    item.type==="select" && 
                    <div>
                        <select  name={item.name} 
                            onChange={(e)=>handleChange(e,item.name)} placeholder={"Enter "+item.name} defaultValue={input[item.name]} 
                            style={{width:'calc(100% + 20px)'}} >
                                <option value={item.name} selected>{input[item.name]}</option>
                                {
                                    item.options.map((opt)=>{
                                        if(opt!==input[item.name]){
                                            return <option value={opt}>{opt}</option>
                                        }
                                        else return ""
                                        
                                    })
                                }
                        </select>
                        
                    </div>
                }
            </div>
        ))
    }

    return <div style={{width:'100%', justifyContent:'center', textAlign:'center'}}>
        
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="stdbox" style={{width:'500px',margin:'50px auto 20px', textAlign:'left',padding:'30px 50px 30px'}}>
                <div style={{padding:'0 0 15px 5px'}}>
                    <h1>Personal Details</h1>
                </div>
                {
                    getForm(personalDetails)
                }

                <div style={{padding:'10px 0 15px 5px'}}>
                    <h1>Occupation Details</h1>
                </div>
                {
                    getForm(occupationDetails)
                }
                <button className="stdButton" style={{width:'100%'}}>Submit</button>

            </div>
        </form>
        
    </div>
}

export default Stage2