
// Default Imports
import store from './components/typeA/store';
import { Provider } from 'react-redux';

// Components
import Root from './components/typeA/root'

function App() {
  return (
    <Provider store={store}>
      <Root/>
    </Provider>
  );
}

export default App;
