/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> Header <<-----

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

// Defaults
import { useSelector } from "react-redux"

const Header = () => {
    const state = useSelector(state=>state)

    const track = (stage) =>{
        if(stage===1) return "Validation"
        else if(stage===2) return "User Details"
        else if(stage===3) return `${state.userData.details.fname}'s Assessments`
        else if(stage===4) return `${state.userData.selectedForm} Assessment`
        else if(stage===5) return "Your Score"
    }
    const clickMe = () =>{
        window.location.reload()
    }

    return <div className="topMenuBackbone">

        <div className="topMenu " >

            <div className="backbone">

                <div onClick={()=>clickMe()} className="topMenu_left" style={{cursor:'pointer'}}><h2>
                    <img alt="" src={"https://greyinsights.org/wp-content/uploads/2022/05/logo-1.png"} height={'80px'}/>
                </h2></div>

                <div style={{border:'0px dashed red',margin:'auto 0px auto auto',display:'flex'}}>
                    <div style={{height:'50px',display:'flex',flexDirection:'column',color:'#aaa'}}>
                        <div style={{margin:'auto 10px 0px auto',fontSize:'small',padding:'0px'}}>Stage</div>
                        <div style={{margin:'0px 10px auto auto',fontSize:'20px',letterSpacing:'1px'}}><b>{track(state.userData.stage)}</b></div>
                    </div>
                    <div style={{width:'50px', height:'50px', background:'#ddd', color:'#888',display:'flex',borderRadius:'10px'}}><div style={{margin:'auto', fontSize:'25px'}}>{state.userData.stage}</div></div>
                </div>
            </div>

        </div>
        
    </div>
}

export default Header