/**
 * This code is Intellectual Property of Laxab Digital Solutions Pvt Ltd, which is a registered company in Pune, India.
 * No part of this code should be copied, edited, transmitted in any form without the written permission of the company.
 */
import { useDispatch, useSelector } from "react-redux"




const Stage3 = () =>{
    const state = useSelector(state=>state)
    const dispatch = useDispatch()

    const handleClick = (form) =>{
        dispatch({
            type:"USER_EDIT",
            forms:state.userData.forms,
            username:state.userData.username,
            code:state.userData.code,
            details:state.userData.details,
            selectedForm:form,
            stage:4
        })
        dispatch({type:"CHANGE_BODY",payload:"Stage4"})

    }

    return <div style={{width:'100%', justifyContent:'center', textAlign:'center'}}>

        <div className="stdbox" style={{width:'500px',margin:'50px auto 20px', textAlign:'left',padding:'30px 50px 30px'}}>
            <div style={{paddingBottom:'20px', textAlign:'center'}}><h1>Select your Assessment</h1></div>
                {
                    state.userData.forms.map((item,i)=>(
                        <button key={i} onClick={()=>handleClick(item)} className="stdButton" style={{width:'100%',margin:'0px 0px'}}>
                            {item}
                        </button>
                    ))
                }
            
        </div>
        
    </div>
}

export default Stage3