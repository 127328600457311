/**
 * This code is Intellectual Property of Laxab Digital Solutions Pvt Ltd, which is a registered company in Pune, India.
 * No part of this code should be copied, edited, transmitted in any form without the written permission of the company.
 */
import { useDispatch } from "react-redux"
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import {ValidateSQLInjection, EmailValidation, Char10} from "../mui/validations"
import fetchData from "../api/api";

const Stage1 = () =>{
    const dispatch = useDispatch();
    const [res,setres] = useState("")
    const { register, handleSubmit, formState: { errors }, trigger } = useForm();
    const [input,setinput] = useState({username:"",code:""})

    const handleChange = async (e,type) =>{
        /**
         * Handle inputs from the form
         */
        if (type==="username"){
            setinput(prev => ({...prev, username:e.target.value}))
            await trigger(e.target.name);
        }
        else if (type==="code"){
            setinput(prev => ({...prev, code:e.target.value}))
            await trigger(e.target.name);
        }
    };

    const onSubmit = () =>{
        /**
         * Handle the actions for submitting the form
         */
        const mappingChecks = async(entity) =>{
            /**
             * Step-2: Check if any forms are mapped to the code
             */
            dispatch({type:'BACKDROP_ON'})
            const url = 'api/ce/publicT1/select'
            const body = {
                "sid": 1,
                "request": "Public_Select",
                "bu":"GreyInsights",
                "type":"Mappings",
                "select":["Form"],
                "condition":[{"Entity":entity,"status":1}],
                "conditionType":"AND",
                "order":{
                    "orderBy":"id",
                    "order":"DESC"
                },
                "pageNumber":1,
                "pageSize":50
            }
            var response = ""
            response = await fetchData(url,body)
            try{
                if(response?.data?.response?.dbData?.length>0){
                    const uniqueValuesSet = new Set();
                    response.data.response.dbData.forEach(item => uniqueValuesSet.add(item.Form)); 
                    dispatch({type:"USER_EDIT",forms:Array.from(uniqueValuesSet),username:input.username,code:entity,stage:2})
                    dispatch({type:"CHANGE_BODY",payload:"Stage2"})
                }
                else {dispatch({type:'BACKDROP_OFF'});setres("Unfortunately, there are no assessments assigned to your organization.");dispatch({type:"USER_RESET"})}
            }
            catch (e){
                setres("Issues in connecting to the database");
                dispatch({type:"USER_RESET"})
            }
        }

        const apiChecks = async() =>{
            /**
             * Step-1: Check if the code is valid
             */
            dispatch({type:'BACKDROP_ON'})
            const url = 'api/ce/publicT1/select'
            const body = {
                "sid": 1,
                "request": "Public_Select",
                "bu":"GreyInsights",
                "type":"code",
                "select":["codedesc"],
                "condition":[{"code":input.code,"status":1}],
                "conditionType":"AND",
                "order":{
                    "orderBy":"id",
                    "order":"DESC"
                },
                "pageNumber":1,
                "pageSize":5
            }
            var response = ""
            response = await fetchData(url,body)
            try{
                if(response.data.response.dbData.length>0){
                    setres("")
                    /**
                     * Call for Step-2
                     */
                    mappingChecks(response.data.response.dbData[0].codedesc)
                }
                else {dispatch({type:'BACKDROP_OFF'});setres("Invalid Code");dispatch({type:"USER_RESET"})}
            }
            catch (e) {dispatch({type:'BACKDROP_OFF'});setres("Issues in connecting to database");dispatch({type:"USER_RESET"})}
        }
        /**
         * Call for step-1
         */
        apiChecks()
    }

    return <div style={{width:'100%', justifyContent:'center', textAlign:'center'}}>

        <form onSubmit={handleSubmit(onSubmit)}>

            <div className="stdbox" style={{width:'500px',margin:'50px auto 20px', textAlign:'left',padding:'30px 50px 30px'}}>

                <div style={{padding:'0px 0 4px 5px'}}>Enter your Email address</div>
                <input 
                    name="username" 
                    {...register(
                        'username',{required:"Mandatory",validate: ValidateSQLInjection,pattern: EmailValidation}
                    )}
                    onKeyUp={(e)=>handleChange(e,'username')} error={errors.username} 
                    style={{width:'calc(100% - 20px)'}} placeholder="" variant="filled"
                />
                {errors.username && <div style={{padding:'4px 0 0 5px',color:'#B5275E',fontSize:'small'}}>{errors.username.message}</div>}

                <div style={{padding:'15px 0 4px 5px'}}>Enter Code</div>
                <input 
                    name="code" 
                    {...register(
                        'code',{required:"Mandatory",validate: ValidateSQLInjection,pattern:Char10}
                    )}
                    onKeyUp={(e)=>handleChange(e,'code')} error={errors.code} 
                    style={{width:'calc(100% - 20px)'}} placeholder="" variant="filled"
                />
                {errors["code"] && <div style={{padding:'4px 0 0 5px',color:'#B5275E',fontSize:'small'}}>{errors["code"].message}</div>}

                <button className="stdButton" style={{width:'100%',margin:'20px 0px 10px'}}>Submit</button>

                {
                    res!=="" &&
                    <div style={{paddingTop:'10px', textAlign:'center',color:"#B5275E"}}>{res}</div>
                }

            </div>

        </form>

        <div>In case of any issues please email us at eap@greyinsights.org</div>

    </div>
}

export default Stage1